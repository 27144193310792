import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Section, Box, Text, Title } from '../../components/Core'
import card5Icon from '../../assets/image/svg/ic_exchanges60.svg'
import card4Icon from '../../assets/image/svg/ic_graph60.svg'
import card3Icon from '../../assets/image/svg/ic_analytics60.svg'
import card2Icon from '../../assets/image/svg/ic_trade60.svg'
import card6Icon from '../../assets/image/svg/ic_apps60.svg'
import card1Icon from '../../assets/image/svg/ic_live60.svg'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import 'isomorphic-fetch'
import { buildApiUrl } from '../../utils/helperFn'

const FeatureCard = ({ icon, title, className, children, ...rest }) => {
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false
  return (
    <Col className="d-flex" lg="4" md="6">
      <Box
        border="1px solid"
        borderColor="border"
        bg={userDarkMode ? 'dark' : 'white'}
        borderRadius={10}
        py="25px"
        px="30px"
        mb={4}
        {...rest}
      >
        <img width="45px" height="45px" src={icon} alt="" className="mb-4" />
        <Title color={userDarkMode ? 'light' : 'dark'} variant="card">
          {title}
        </Title>
        <Text color={userDarkMode ? 'light' : 'dark'} variant="small">
          {children}
        </Text>
      </Box>
    </Col>
  )
}

const Feature = () => {
  const { t } = useTranslation()
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  const [headerCounts, setHeaderCounts] = useState(false)
  const fetchData = () => {
    fetch(buildApiUrl(`/public/stats`))
      .then(response => response.json())
      .then(resultData => {
        if (resultData.success) {
          setHeaderCounts(resultData.result)
        }
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      {/* <!-- Feature section --> */}
      <Section
        pb={[0, null, null, 0]}
        bg={userDarkMode ? 'black' : 'bg'}
        className="position-relative"
      >
        <Container>
          <Title color={userDarkMode ? 'light' : 'dark'} mb={4}>
            {t('apiPage.endlessPossibilities')}
          </Title>
          <Row>
            <Col className="d-flex" lg="4" md="6">
              <Box
                border="1px solid"
                borderColor="border"
                borderRadius={10}
                bg={userDarkMode ? 'dark' : 'white'}
                py="25px"
                px="30px"
                mb={4}
              >
                <Title
                  color={userDarkMode ? 'primaryLight' : 'primary'}
                  variant="card"
                >
                  {parseFloat(
                    headerCounts.totalActiveCurrencies
                  ).toLocaleString()}{' '}
                  {t('currencies')}
                </Title>
                <Text color={userDarkMode ? 'light' : 'dark'} variant="small">
                  {t('apiPage.blockchainData')}
                </Text>
              </Box>
            </Col>
            <Col className="d-flex" lg="4" md="6">
              <Box
                border="1px solid"
                borderColor="border"
                borderRadius={10}
                bg={userDarkMode ? 'dark' : 'white'}
                py="25px"
                px="30px"
                mb={4}
              >
                <Title
                  color={userDarkMode ? 'primaryLight' : 'primary'}
                  variant="card"
                >
                  {parseFloat(headerCounts.totalActiveMarkets).toLocaleString()}{' '}
                  {t('markets')}
                </Title>
                <Text color={userDarkMode ? 'light' : 'dark'} variant="small">
                  {t('apiPage.everyMarket')}
                </Text>
              </Box>
            </Col>
            <Col className="d-flex" lg="4" md="6">
              <Box
                border="1px solid"
                borderColor="border"
                borderRadius={10}
                bg={userDarkMode ? 'dark' : 'white'}
                py="25px"
                px="30px"
                mb={4}
              >
                <Title
                  color={userDarkMode ? 'primaryLight' : 'primary'}
                  variant="card"
                >
                  {parseFloat(
                    headerCounts.totalActiveExchanges
                  ).toLocaleString()}{' '}
                  {t('exchanges')}
                </Title>
                <Text color={userDarkMode ? 'light' : 'dark'} variant="small">
                  {t('apiPage.tradeAnalyze')}
                </Text>
              </Box>
            </Col>

            <FeatureCard icon={card1Icon} title={t('apiPage.liveExchRates')}>
              {t('apiPage.lowLatency')}
            </FeatureCard>
            <FeatureCard icon={card2Icon} title={t('apiPage.highFreq')}>
              {t('apiPage.perfectSuite')}
            </FeatureCard>
            <FeatureCard
              icon={card3Icon}
              title={t('apiPage.blockchainAnalysis')}
            >
              {t('apiPage.digDeep')}
            </FeatureCard>
            <FeatureCard
              icon={card4Icon}
              title={t('apiPage.dataVisualization')}
            >
              {t('apiPage.simplyTheBest')}
            </FeatureCard>
            <FeatureCard icon={card5Icon} title={t('apiPage.smartContracts')}>
              {t('apiPage.oracle')}
            </FeatureCard>
            <FeatureCard icon={card6Icon} title={t('Apps')}>
              {t('apiPage.apiPowers')}
            </FeatureCard>
          </Row>
        </Container>
      </Section>
    </>
  )
}

export default Feature
