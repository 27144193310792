import * as styles from '../sections/landing1/Home.module.css'
import MetaData from '../components/MetaData'
import React from 'react'
import PageWrapper from '../components/PageWrapper'
import Hero from '../sections/common/Hero'
import GetStarted from '../sections/landing1/GetStarted'
import { Text } from '../components/Core'
import GithubLogo from '../assets/image/svg/github.svg'
import ApiDocLogo from '../assets/image/svg/apiary.svg'
import Features from '../sections/api/Features'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import 'isomorphic-fetch'
import { theme } from '../utils'

const CoinigyApi = () => {
  const { t, ready } = useTranslation(['api'], { useSuspense: false })
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  return ready && (
    <>
      <MetaData page="default" />
      <PageWrapper headerDark footerDark>
        <Hero title={t('coinigyApi')}>
          <Text
            className={`mb-4`}
            color={userDarkMode ? theme.colors.light : theme.colors.dark}
          >
            {t('allInOne')}. {t('getStarted')}:
          </Text>
          <a
            href="https://coinigy.docs.apiary.io/"
            target="_blank"
            rel="noopener noreferrer"
            className={`${styles.aTagButton} mr-4 my-2`}
          >
            <img
              width="20"
              height="20"
              style={{ marginRight: '0.5rem' }}
              src={ApiDocLogo}
              alt="Apiary"
            />
            {t('apiDoc')}
          </a>
          <a
            href="https://github.com/coinigy/"
            target="_blank"
            rel="noopener noreferrer"
            className={`${styles.aTagButton} mr-4 my-2`}
          >
            <img
              width="20"
              height="20"
              className={`mr-3`}
              src={GithubLogo}
              alt="Github"
            />
            {t('codeExamples')}
          </a>
        </Hero>
        <Features />
        <GetStarted />
      </PageWrapper>
    </>
  )
}
export default CoinigyApi
